/* globals $ */
import cn from 'classnames';
import React from 'react';
import { template } from 'app/blocks/common/utils';
import middleware from 'app/blocks/middleware/static';

const CACHE = {};

const HIDDEN = document.body.appendChild(document.createElement('div'));
HIDDEN.style.position = 'fixed';
HIDDEN.style.bottom = '100vh';
HIDDEN.style.right = '100vw';
HIDDEN.style.height = '100vh'; // how to keep width between show/hide/show?
HIDDEN.style.width = '100vw';

const showDrawer = "Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')";
const cookiePreferences = `<a class="FooterContainer-Link cookiePreferences" target="_blank"  onclick="${showDrawer}">Cookie Preferences</a>`;

type State = {
    error?: string;
    html?: string;
    isLoading: boolean;
    runOnce: boolean;
    scriptedContainer?: React.RefObject<HTMLDivElement>;
};

class StaticContent extends React.PureComponent<
    Either<{
        getBody: () => Promise<string>;
        html: string;
        src: string;
    }> & {
        className?: string;
        hideLoadingText?: boolean;
        onLoaded?: () => void;
        params?: Record<string, any>;
        seleniumid?: string;
    },
    State
> {
    static defaultProps = {
        hideLoadingText: false,
        onLoaded: () => {},
    };

    state: State = {
        html: this.props.html,
        isLoading: !this.props.html,
        runOnce: false,
    };

    async componentDidMount() {
        const { getBody, html, onLoaded, params, src } = this.props;

        if (html || (!src && !getBody)) {
            return;
        }

        try {
            let preparedBody;

            if (typeof getBody === 'function') {
                preparedBody = await getBody();
            } else if (src) {
                preparedBody = template(await middleware.getHtml(src), { ...params, cookiePreferences });
            }

            let scripted = false;
            let runOnce = false;
            // @ts-ignore
            String(preparedBody).replace(/<!--\s*scripts\s*:\s*([a-z-]+?)\s*-->/i, ($0, $1) => {
                scripted = !!$1;
                runOnce = String($1).toLowerCase() !== 'stateless';
            });

            // if already rendered now in different instance
            // item in CACHE means that it was 'runOnce'
            if (src && CACHE[src] && CACHE[src].parentNode !== HIDDEN) {
                scripted = false;
                runOnce = false;
                preparedBody = '';
                console.error('Duplication of scripted content');
            }

            this.setState(
                {
                    html: preparedBody,
                    isLoading: false,
                    runOnce,
                    scriptedContainer: scripted ? React.createRef() : undefined,
                },
                onLoaded,
            );
        } catch (error) {
            this.setState({
                error: `<span class='bg-danger text-danger'>${error.message}</span>`,
                isLoading: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { html, runOnce, scriptedContainer } = this.state;
        const { className, seleniumid, src } = this.props;

        function createInner(appendTo) {
            const inner = document.createElement('div');
            appendTo.appendChild(inner);
            try {
                // @ts-ignore
                $(inner).html(html); // using jQuery to run scripts in html
            } catch (error) {
                console.error(`Error executing script from ${src}`);
                console.error(error);
            }
            return inner;
        }

        const div = scriptedContainer?.current;
        if (div && !prevState.scriptedContainer) {
            // first time after container with the future scripts created

            let inner;
            if (runOnce) {
                if (!CACHE[src]) {
                    CACHE[src] = createInner(div);
                } else {
                    div.appendChild(CACHE[src]);
                }

                inner = CACHE[src];
            } else {
                inner = createInner(div);
            }

            inner.className = className;
            inner.setAttribute('data-seleniumid', `${seleniumid}-content`);
        }
    }

    componentWillUnmount() {
        if (this.state.runOnce) {
            HIDDEN.appendChild(CACHE[this.props.src]);
        }
    }

    render() {
        const { error, html, isLoading, scriptedContainer } = this.state;
        const { className, hideLoadingText, seleniumid } = this.props;

        const loadingText = hideLoadingText ? '' : 'Loading...';

        return !scriptedContainer ? (
            <div
                className={cn(className, { loading: isLoading })}
                dangerouslySetInnerHTML={{ __html: isLoading ? loadingText : error || html }}
                data-seleniumid={`${seleniumid}-content`}
            />
        ) : (
            <div ref={scriptedContainer} />
        );
    }
}

export default StaticContent;
